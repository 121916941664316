import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from 'antd';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const { Paragraph } = Typography;

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Paragraph>{children}</Paragraph>
    ),
  },
};

const RichText = function ({ text }) {
  return (
    documentToReactComponents(text, RICHTEXT_OPTIONS)
  );
};

RichText.propTypes = {
  text: PropTypes.object.isRequired,
};

export default RichText;
